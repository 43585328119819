import { render, staticRenderFns } from "./GloVars.vue?vue&type=template&id=61093bc6&scoped=true&"
import script from "./GloVars.vue?vue&type=script&lang=js&"
export * from "./GloVars.vue?vue&type=script&lang=js&"
import style0 from "./GloVars.vue?vue&type=style&index=0&id=61093bc6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61093bc6",
  null
  
)

export default component.exports