<template>
  <div class="">
    <div class="row">
      <div class="col-md-12">
        <h3 class="text-center"> MAPPING QUALITY CONTROL </h3>
      </div>

      <div class="col-md-12"></div>
      <div v-if="Object.keys(qc_picard_hs).length !== 0" class="col-md-12 mr-auto ml-auto">
        <card>
          <el-table
            :data="[qc_picard_hs]"
            border
            :header-cell-style="tableHeaderStyle"
            style="width: 100%">
            <el-table-column
              prop="genome_size"
              label="Genome size"
              min-width="160"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.genome_size.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="total_reads"
              label="Total reads"
              min-width="160"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.total_reads.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="pf_unique_reads"
              label="PF uniq reads"
              min-width="160"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.pf_unique_reads.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="pct_pf_uq_reads"
              label="% PF uniq reads"
              min-width="160"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.pct_pf_uq_reads.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="pf_uq_reads_aligned"
              label="PF uniq reads aligned"
              min-width="200"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.pf_uq_reads_aligned.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="pct_pf_uq_reads_aligned"
              label="% PF uniq reads aligned"
              min-width="200"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.pct_pf_uq_reads_aligned.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>
          </el-table>
        </card>
      </div>

      <div v-if="Object.keys(qc_picard_amp).length !== 0" class="col-md-12 mr-auto ml-auto">
        <card>
          <el-table
            :data="[qc_picard_amp]"
            border
            :header-cell-style="tableHeaderStyle"
            style="width: 100%">
            <el-table-column
              prop="genome_size"
              label="Genome size"
              min-width="160"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.genome_size.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="target_territory"
              label="Target territory"
              min-width="160"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.target_territory.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="total_reads"
              label="Total reads"
              min-width="160"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.total_reads.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="pf_uq_reads_aligned"
              label="PF uniq reads aligned"
              min-width="200"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.pf_uq_reads_aligned.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="pct_pf_uq_reads_aligned"
              label="% PF uniq reads aligned"
              min-width="200"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.pct_pf_uq_reads_aligned.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>
          </el-table>
        </card>
      </div>

      <div class="col-md-12"></div>
      <el-divider></el-divider>
    </div>

    <div v-if="Object.keys(qc_picard_hs).length !== 0" class="row">
      <div class="col-md-12">
        <h4 class="text-center"></h4>
      </div>

      <div class="col-md-12 mr-auto ml-auto">
        <card>
          <el-table
            :data="[qc_picard_hs]"
            border
            :header-cell-style="tableHeaderStyle"
            style="width: 100%"
            align="center"
            >
            <el-table-column
              prop="median_target_coverage"
              label="Median coverage"
              min-width="140"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.median_target_coverage.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="mean_target_coverage"
              label="Mean coverage"
              min-width="140"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.mean_target_coverage.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="max_target_coverage"
              label="Max coverage"
              min-width="140"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.max_target_coverage.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="min_target_coverage"
              label="Min coverage"
              min-width="140"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.min_target_coverage.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="fold_80_base_penalty"
              label="Uniformity"
              min-width="140"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.fold_80_base_penalty.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="pct_exc_dupe"
              label="% PCR Duplicates"
              min-width="180"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.pct_exc_dupe.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="zero_cvg_targets_pct"
              label="% Zero target coverage"
              min-width="180"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.zero_cvg_targets_pct.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>
          </el-table>
        </card>
      </div>

      <el-divider></el-divider>
    </div>

    <div v-if="Object.keys(qc_picard_hs).length !== 0" class="row">
      <div class="col-md-12">
        <h4 class="text-center"></h4>
      </div>

      <div class="col-md-12 mr-auto ml-auto">
        <card>
          <el-table
            :data="[qc_picard_hs]"
            border
            :header-cell-style="tableHeaderStyle"
            style="width: 100%"
            >
            <el-table-column
              prop="pct_selected_bases"
              label="% On-target"
              min-width="130"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.pct_selected_bases.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="pct_off_bait"
              label="% Off-target"
              min-width="130"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.pct_off_bait.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="pct_exc_adapter"
              label="% Adapters"
              min-width="130"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.pct_exc_adapter.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="het_snp_sensitivity"
              label="HET SNP sensitivity"
              min-width="160"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.het_snp_sensitivity.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="het_snp_q"
              label="HET SNP quality"
              min-width="160"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.het_snp_q.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="at_dropout"
              label="AT dropout"
              min-width="170"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.at_dropout.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="gc_dropout"
              label="GC dropout"
              width="170"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.gc_dropout.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>
          </el-table>
        </card>
      </div>

      <el-divider></el-divider>
    </div>

    <div v-if="Object.keys(qc_picard_amp).length !== 0" class="row">
      <div class="col-md-12">
        <h4 class="text-center"></h4>
      </div>

      <div class="col-md-12 mr-auto ml-auto">
        <card>
          <el-table
            :data="[qc_picard_amp]"
            border
            :header-cell-style="tableHeaderStyle"
            style="width: 100%">
            <el-table-column
              prop="median_target_coverage"
              label="Median coverage"
              min-width="130"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.median_target_coverage.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="mean_target_coverage"
              label="Mean coverage"
              min-width="130"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.mean_target_coverage.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="max_target_coverage"
              label="Max coverage"
              min-width="130"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.max_target_coverage.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="min_target_coverage"
              label="Min coverage"
              min-width="160"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.min_target_coverage.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="zero_cvg_targets_pct"
              label="Zero coverage"
              min-width="160"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.zero_cvg_targets_pct.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="at_dropout"
              label="AT dropout"
              min-width="170"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.at_dropout.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="gc_dropout"
              label="GC dropout"
              min-width="170"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.gc_dropout.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>
          </el-table>
        </card>
      </div>

      <el-divider></el-divider>
    </div>

    <div v-if="qc_picard_amp_target.length !== 0" class="row">
      <div class="col-md-12">
        <h4 class="text-center"> Hotspot coverage </h4>
      </div>
      <div class="col-md-12 mr-auto ml-auto">
        <card>
          <el-table
            :data="qc_picard_amp_target"
            border
            :max-height="700"
            :header-cell-style="tableHeaderStyle"
            style="width: 100%"
            show-summary
            :summary-method="qc_picard_amp_target_summary"
            >
            <el-table-column
              prop="name"
              label="Name"
              min-width="280"
              align="left"
              >
            </el-table-column>

            <el-table-column
              prop="min_coverage"
              label="Min coverage"
              min-width="120"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.min_coverage.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="mean_coverage"
              label="Mean coverage"
              min-width="120"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.mean_coverage.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="max_coverage"
              label="Max coverage"
              min-width="110"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.max_coverage.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="pct_0x"
              label="% 0x"
              min-width="80"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.pct_0x.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="read_count"
              label="Reads count"
              min-width="100"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.read_count.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="chrom"
              label="Chr"
              min-width="90"
              align="center"
              >
            </el-table-column>

            <el-table-column
              prop="c_start"
              label="Start"
              min-width="120"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.c_start.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="c_end"
              label="Stop"
              min-width="120"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.c_end.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="length"
              label="Length"
              min-width="80"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.length.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              prop="gc"
              label="GC"
              min-width="100"
              align="center"
              >
              <template slot-scope="scope">
                  {{ scope.row.gc.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>
          </el-table>
        </card>
      </div>
    </div>

    <div v-if="chart_bar_a.length !== 0" class="col-md-12 mr-auto ml-auto">
      <div class="col-md-12">
        <h4 class="text-center">Target coverage</h4>
      </div>
      <div class="card">
        <div class="card-body mr-auto ml-auto">
          <slot name="title-content">
            <div class="row">
              <div class="col-sm-7">
                <div class="numbers pull-left">
                  <slot name="title"></slot>
                </div>
              </div>
              <div class="col-sm-5">
                <div class="pull-right">
                  <slot name="title-label"></slot>
                </div>
              </div>
            </div>
          </slot>
          <slot name="subtitle">Bases in target region [%]</slot>
          <la-cartesian narrow :data="chart_bar_a" :width="800">
            <la-bar prop="value"></la-bar>
            <la-x-axis :fontSize="13" prop="name"></la-x-axis>
            <la-y-axis :fontSize="13" :nbTicks="5" gridline prop="XXXXXX" :format="v => parseInt(v * 100)"></la-y-axis>
            <la-tooltip></la-tooltip>

          </la-cartesian>
          <div class="col-sm-12">
            <div class="pull-right">
              <slot name="title-label">Coverage</slot>
            </div>
          </div>

        </div>

        <div class="card-footer">
          <slot name="footer">
            <hr>
            <div class="row">
              <div class="col-sm-7">
                <div class="footer-title">
                  <slot name="footer-title"></slot>
                </div>
              </div>
              <div class="col-sm-5">
                <div class="pull-right">
                  <slot name="footer-right"></slot>
                </div>
              </div>
            </div>
          </slot>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  import moment from 'moment';
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import axios from 'axios';

  import CircleChartCard from 'src/components/UIComponents/Cards/CircleChartCard.vue';
  import ChartCard from 'src/components/UIComponents/Cards/ChartCard';
  import StatsCard from 'src/components/UIComponents/Cards/StatsCard';
  import LineChart from 'src/components/UIComponents/Charts/LineChart';
  import BarChart from 'src/components/UIComponents/Charts/BarChart';
  import PieChart from 'src/components/UIComponents/Charts/PieChart';
  import {Badge, Card} from 'src/components/UIComponents';
  import TaskList from './Dashboard/Widgets/TaskList';

  import {Table, TableColumn, Divider } from 'element-ui';


  const tooltipOptions = {
    tooltipFillColor: "rgba(0,0,0,0.5)",
    tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipFontSize: 14,
    tooltipFontStyle: "normal",
    tooltipFontColor: "#fff",
    tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipTitleFontSize: 14,
    tooltipTitleFontStyle: "bold",
    tooltipTitleFontColor: "#fff",
    tooltipYPadding: 6,
    tooltipXPadding: 6,
    tooltipCaretSize: 8,
    tooltipCornerRadius: 6,
    tooltipXOffset: 10,
  };

  export default {
    name: 'exam_qc',
    props: {
      got_id_examination: {
        type: String,
        default: '0',
        description: ''
      },
    },
    components: {
      StatsCard,
      CircleChartCard,
      Badge,
      TaskList,
      Card,
      ChartCard,
      LineChart,
      BarChart,
      PieChart,
      Table,
      TableColumn,
      Divider,
    },

    computed: {
      ...mapState({
        token: state => state.token,
        qc_picard_hs: state => state.qc_picard_hs,
        qc_picard_amp: state => state.qc_picard_amp,
        qc_picard_amp_target: state => state.qc_picard_amp_target,
        id_examination: state => state.id_examination,
        chart_bar_a: state => state.chart_bar_a,
      }),
    },


    data () {
      return {
        mydata: [
          33,
          22,
        ],
        aa: 11,
        bb: 88,


        chart_test: [
          { name: 'Page A', pv: 2000 },
          { name: 'Page B', pv: 3000 },
          { name: 'Page C', pv: 1200 }
        ],

        chart_on_target: [
          { name: 'Page A', pv: 2000 },
          { name: 'Page B', pv: 3000 },
          { name: 'Page C', pv: 1200 }
        ],


        activeUsersChart: {
          data: {
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"],
            series: [542, 480, 430, 550, 530, 453, 380, 434, 568, 610]
          }
        },

        // emailsCampaignChart: {
        //   data: {
        //     labels: ["12pm", "3pm", "6pm", "9pm", "12am", "3am", "6am", "9am"],
        //     series: [40, 500, 650, 700, 1200, 1250, 1300, 1900]
        //   }
        // },

        // activeCountriesChart: {
        //   data: {
        //     labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October"],
        //     series: [80, 78, 86, 96, 83, 85, 76, 75, 88, 90]
        //   }
        // },

        // activityChart: {
        //   labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
        //   datasets: [
        //     {
        //       label: "Data",
        //       borderColor: '#fcc468',
        //       fill: true,
        //       backgroundColor: '#fcc468',
        //       hoverBorderColor: '#fcc468',
        //       borderWidth: 8,
        //       data: [100, 120, 80, 100, 90, 130, 110, 100, 80, 110, 130, 140, 130, 120, 130, 80, 100, 90, 120, 130],
        //     },
        //     {
        //       label: "Data",
        //       borderColor: '#4cbdd7',
        //       fill: true,
        //       backgroundColor: '#4cbdd7',
        //       hoverBorderColor: '#4cbdd7',
        //       borderWidth: 8,
        //       data: [80, 140, 50, 120, 50, 150, 60, 130, 50, 130, 150, 100, 110, 80, 140, 50, 140, 50, 110, 150],
        //     }
        //   ],
        //   options: {
        //     tooltips: tooltipOptions
        //   }
        // },

        // stockChart: {
        //   labels: ["6pm", "9pm", "11pm", "2am", "4am", "6am", "8am"],
        //   datasets: [{
        //     label: "Active Users",
        //     borderColor: "#f17e5d",
        //     pointBackgroundColor: "#f17e5d",
        //     pointRadius: 3,
        //     pointHoverRadius: 3,
        //     lineTension: 0,
        //     fill: false,
        //     borderWidth: 3,
        //     data: [200, 250, 300, 350, 280, 330, 400]
        //   }],
        //   color: '#f17e5d',
        //   options: {
        //     tooltips: tooltipOptions,
        //     scales: {
        //       yAxes: [{

        //         ticks: {
        //           fontColor: "#9f9f9f",
        //           beginAtZero: false,
        //           maxTicksLimit: 3,
        //         },
        //         gridLines: {
        //           drawBorder: false,
        //           borderDash: [8, 5],
        //           zeroLineColor: "transparent",
        //           color: '#9f9f9f'
        //         }

        //       }],

        //       xAxes: [{
        //         barPercentage: 1.6,
        //         gridLines: {
        //           drawBorder: false,
        //           borderDash: [8, 5],
        //           color: '#9f9f9f',
        //           zeroLineColor: "transparent"
        //         },
        //         ticks: {
        //           padding: 20,
        //           fontColor: "#9f9f9f"
        //         }
        //       }]
        //     }
        //   }
        // },

        // viewsChart: {
        //   labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
        //   datasets: [
        //     {
        //       label: "Data",
        //       borderColor: '#fcc468',
        //       fill: true,
        //       backgroundColor: '#fcc468',
        //       hoverBorderColor: '#fcc468',
        //       borderWidth: 5,
        //       data: [100, 120, 80, 100, 90, 130, 110, 100, 80, 110, 130, 140, 130, 120, 130, 80, 100, 90, 120, 130],
        //     }
        //   ]
        // },


        // bioxsysChart: {
        //   labels: [1, 2],
        //   datasets: [{
        //     label: "Bioxsys",
        //     pointRadius: 0,
        //     pointHoverRadius: 0,
        //     backgroundColor: [
        //       'cyan',
        //       'yellow',
        //     ],
        //     borderWidth: 0,
        //     data: [this.qc_a["pct_selected_bases"], this.qc_a["pct_off_bait"]]
        //   }],
        //   options: {
        //     tooltips: tooltipOptions
        //   }
        // },

        // concurrentChart: {
        //   labels: [1, 2],
        //   datasets: [{
        //     label: "Concurrent",
        //     pointRadius: 0,
        //     pointHoverRadius: 0,
        //     backgroundColor: [
        //       'green',
        //       'red',
        //     ],
        //     borderWidth: 0,
        //     data: [20, 80]
        //   }],
        //   options: {
        //     tooltips: tooltipOptions
        //   }
        // },

        // emailChart: {
        //   labels: [1, 2, 3],
        //   datasets: [{
        //     label: "Emails",
        //     pointRadius: 0,
        //     pointHoverRadius: 0,
        //     backgroundColor: [
        //       '#e3e3e3',
        //       '#4acccd',
        //       '#fcc468'
        //     ],
        //     borderWidth: 0,
        //     data: [542, 480, 430]
        //   }],
        //   options: {
        //     tooltips: tooltipOptions
        //   }
        // },

        // chartHours: {
        //   data: {

        //     labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"],
        //     datasets: [{
        //       borderColor: "#6bd098",
        //       backgroundColor: "#6bd098",
        //       pointRadius: 0,
        //       pointHoverRadius: 0,
        //       borderWidth: 3,
        //       data: [300, 310, 316, 322, 330, 326, 333, 345, 338, 354]
        //     },
        //       {
        //         borderColor: "#f17e5d",
        //         backgroundColor: "#f17e5d",
        //         pointRadius: 0,
        //         pointHoverRadius: 0,
        //         borderWidth: 3,
        //         data: [320, 340, 365, 360, 370, 385, 390, 384, 408, 420]
        //       },
        //       {
        //         borderColor: "#fcc468",
        //         backgroundColor: "#fcc468",
        //         pointRadius: 0,
        //         pointHoverRadius: 0,
        //         borderWidth: 3,
        //         data: [370, 394, 415, 409, 425, 445, 460, 450, 478, 484]
        //       }
        //     ]
        //   },
        //   options: {
        //     tooltips: tooltipOptions,
        //     scales: {
        //       yAxes: [{

        //         ticks: {
        //           fontColor: "#9f9f9f",
        //           beginAtZero: false,
        //           maxTicksLimit: 5,
        //           //padding: 20
        //         },
        //         gridLines: {
        //           drawBorder: false,
        //           zeroLineColor: "transparent",
        //           color: 'rgba(255,255,255,0.05)'
        //         }

        //       }],

        //       xAxes: [{
        //         barPercentage: 1.6,
        //         gridLines: {
        //           drawBorder: false,
        //           color: 'rgba(255,255,255,0.1)',
        //           zeroLineColor: "transparent",
        //           display: false,
        //         },
        //         ticks: {
        //           padding: 20,
        //           fontColor: "#9f9f9f"
        //         }
        //       }]
        //     }
        //   }
        // },


        // tableData: [{
        //   date: '2016-05-03',
        //   name: 'Tom',
        //   address: 'No. 189, Grove St, Los Angeles'
        // }, {
        //   date: '2016-05-02',
        //   name: 'Tom',
        //   address: 'No. 189, Grove St, Los Angeles'
        // }, {
        //   date: '2016-05-04',
        //   name: 'Tom',
        //   address: 'No. 189, Grove St, Los Angeles'
        // }, {
        //   date: '2016-05-01',
        //   name: 'Tom',
        //   address: 'No. 189, Grove St, Los Angeles'
        // }],

      }

    },


    methods: {
      tableHeaderStyle ({row, column, rowIndex, columnIndex}) {
        return 'color:#000;font-weight:800;'
      },


      getRandomInt () {
        return Math.floor(Math.random() * (50 - 5 + 1)) + 5
      },


      qc_picard_amp_target_summary (param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = 'Sum';
            return;
          } else if (index === 5) {
            const values = data.map(item => Number(item[column.property]));
            if (!values.every(value => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] = sums[index].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            } else {
              sums[index] = '';
            }
          } else {
            sums[index] = '';
          }

        });

        return sums;
      }




    },






  }

</script>
<style scoped>
  .bottom_margin_10 {
    margin-bottom: 10px;
  }

  .top_padd {
    padding-top: 90px !important;
  }

.el-table {
    color: black !important;
}


el-table>.thead {
    color: red !important;
}

</style>
