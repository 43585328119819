<template>
  <div class="dolu" style="background: transparent; position: relative;">

    <!-- Bioxsys homepage -->
    <div v-if="application === 'GENOVESA'" class="overlay">
      <el-row  type="flex" class="row-bg" justify="center" :gutter="20" style="background: transparent !important;">
        <el-col :span="16">
          <div v-show="!fastgen && !vlngsa" class="grid-content sedy_blok overlay" style="background: transparent !important;">
            <el-row>
              <el-col :span="24">
                <div class="grid-content dolu text_a" style="background: transparent !important; font-size: 26px;">
                    <h2 style="padding-top: 80px;">Welcome to GENOVESA</h2>                    <p>
                      Genovesa is your advanced bioinformatics analysis portal, including your own database of variants.
                      <br>
                      Designed to streamline genomic data processing, it facilitates direct and efficient variant interpretation for healthcare and research applications.
                    </p>
                </div>
              </el-col>

              <el-col v-show="!(token > '')" :span="24">
                <div class="grid-content dolu" style="background: transparent !important;">
                </div>
              </el-col>

              <el-col :span="24">
                <div class="grid-content dolu text_a text_b" style="background: transparent !important; font-size: 20px;">
                  <h3>Getting Started with GENOVESA:</h3>
                  <ul>
                    <li>Watch our <a class="link_bioxsys" href="https://youtu.be/e-to02RTkTE?si=FmqHJX1kyCm3WyWL" target="_blank">Video Tutorial</a> to get familiar with the platform.</li>
                    <li>Download the <a class="link_bioxsys" href="https://www.biovendor.com/genovesa-bioinformatic-analysis-software/pds.pdf" target="_blank">User Guide</a> for in-depth documentation.</li>
                  </ul>
                </div>
              </el-col>

              <el-col :span="24">
                <div class="grid-content dolu text_a text_b" style="background: transparent !important; font-size: 20px;">
                  <h4>Need Assistance?</h4>
                  <p>If you encounter any issues or need technical support, please feel free to contact our support team at <a class="link_bioxsys" href="mailto:info@bioxsys.cz">info@bioxsys.cz</a>.</p>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- Biovendor blaboly -->
    <el-row v-if="application === 'NGS-GENOVESA'" type="flex" class="row-bg" justify="center" :gutter="20">
      <el-col :span="4">
        <div class="grid-content dolu">
          <a  v-show="!(token > '')" href="https://www.biovendor.group?utm_source=webgenovesa&amp;utm_medium=web-footer-logo" target="blank" class="logo" title="We bring to the market diagnostic tools for innovative methods of molecular diagnostics from BioVendor Group laboratories - NGS, LAMP and miRNA.">
            <img src="/img/bv/BG_NGS_logo.svg" alt="BioVendor Group ENG" />
          </a>
        </div>
      </el-col>
      <el-col :span="16">
        <div v-show="!fastgen && !vlngsa" class="grid-content sedy_blok">
          <el-row>
            <el-col :span="24">
              <div class="grid-content dolu text_a_bv">
                Welcome to GENOVESA, the bioinformatic analysis portal.
              </div>
            </el-col>

            <el-col v-show="!(token > '')" :span="24">
              <div class="grid-content dolu">
              </div>
            </el-col>

            <el-col :span="24">
              <div class="grid-content dolu text_c_bv">
                Get started:
              </div>
              <br  />
            </el-col>

            <el-col :span="20" :offset="4">
              <div class="grid-content">
                <p> <a href="https://youtu.be/e-to02RTkTE?si=FmqHJX1kyCm3WyWL" target="_blank"> Watch the Video Tutorial </a> </p>
                <p> <a href="https://www.biovendor.com/genovesa-bioinformatic-analysis-software/pds.pdf" target="_blank"> Read the User Guide </a> </p>
              </div>
            </el-col>

            <el-col v-show="!(token > '')" :span="24">
              <div class="grid-content dolu">
              &nbsp;
              </div>
            </el-col>

            <el-col :span="5">
              <div class="grid-content dolu">
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content dolu">
                <el-button v-if="!(token > '')" @click="m_modal_login(true)" class="my_butt_1">Sign-In</el-button>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content dolu">
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content dolu">
                <el-button v-if="!(token > '')" @click="m_modal_reg(true)" class="my_butt_1"> Register </el-button>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content dolu">
              </div>
            </el-col>

            <el-col v-show="!(token > '')" :span="24">
              <div class="grid-content dolu">
              &nbsp;
              </div>
            </el-col>

          </el-row>
        </div>

        <div v-show="fastgen" class="grid-content sedy_blok">
          <el-row>
            <el-col :span="24">
              <div class="grid-content dolu text_aa">
                 Three reasons why GENOVESA is worth your attention:
              </div>
              <el-divider></el-divider>
            </el-col>
            <el-col :span="24">
              <el-row>
                <el-col :span="6">
                  <div class="grid-content dolu text_a">
                    AUTOMATIC
                  </div>
                </el-col>
                <el-col :span="18">
                  <div class="grid-content dolu text_b">
                    - generation of sequencing and target enrichment Quality Control
                  </div>
                  <div class="grid-content text_b">
                    - variant calling (SNV, InDel) and annotation
                  </div>
                  <div class="grid-content text_b">
                    - notification of low-coverage regions for simple filtering of relevant variants
                  </div>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="6">
                  <div class="grid-content dolu text_a">
                    DATABASES
                  </div>
                </el-col>
                <el-col :span="18">
                  <div class="grid-content dolu text_b">
                    - update the annotations monthly ​
                  </div>
                  <div class="grid-content text_b">
                    - store patient data and variants internal​
                  </div>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="6">
                  <div class="grid-content dolu text_a">
                    REPORTS
                  </div>
                </el-col>
                <el-col :span="18">
                  <div class="grid-content dolu text_b">
                    - on one-click​
                  </div>
                </el-col>
              </el-row>
            </el-col>

            <el-col :span="3">
              <div class="grid-content dolu">
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content dolu">
                <el-button v-if="!(token > '')" @click="m_modal_login(true)" class="my_butt_1">Sign-In</el-button>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content dolu">
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content dolu">
                <el-button v-if="!(token > '')" @click="m_modal_reg(true)" class="my_butt_5"> Register </el-button>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content dolu">
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content dolu">
                <el-button v-if="!(token > '')" @click="go_back_welcome" class="my_butt_3"> Back </el-button>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content dolu">
              </div>
            </el-col>

            <el-col :span="24">
              <div class="grid-content dolu">
              </div>
            </el-col>
          </el-row>
        </div>

        <div v-show="vlngsa" class="grid-content sedy_blok">
          <el-row>
            <el-col :span="24">
              <div class="grid-content dolu text_a">
                GENOVESA, a tailored pipeline for ViennaLab NGS Assays enables:​​
              </div>
            </el-col>
            <el-col :span="24">
              <div class="grid-content dolu text_b">
                - upload of NGS panel data (fastq-file format)​
              </div>
              <div class="grid-content text_b">
                - automatic generation of sequencing and target enrichment Quality Control​
              </div>
              <div class="grid-content text_b">
                - automatic variant calling (SNV, InDel) and annotation​
              </div>
              <div class="grid-content text_b">
                - optimized ViennaLab filters to identify biologically relevant variants ​
              </div>
              <div class="grid-content text_b">
                - customizable filters to allow comprehensive investigation of data ​
              </div>
              <div class="grid-content text_b">
                - visual display of SNV and InDel variants using a seamlessly integrated viewer ​
              </div>
              <div class="grid-content text_b">
                - report generation of significant genetic variants​
              </div>
            </el-col>

            <el-col :span="3">
              <div class="grid-content dolu">
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content dolu">
                <el-button v-if="!(token > '')" @click="m_modal_login(true)" class="my_butt_1">Sign-In</el-button>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content dolu">
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content dolu">
                <el-button v-if="!(token > '')" @click="m_modal_reg(true)" class="my_butt_5"> Register </el-button>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content dolu">
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content dolu">
                <el-button v-if="!(token > '')" @click="go_back_welcome" class="my_butt_3"> Back </el-button>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content dolu">
              </div>
            </el-col>

            <el-col :span="24">
              <div class="grid-content dolu">
              </div>
            </el-col>
          </el-row>
        </div>

      </el-col>
      <el-col :span="4">
        <div class="grid-content text">
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

  export default {
    components: {

    },


    data () {
      return {
        fastgen: false,
        vlngsa: false,

      }
    },

    computed: {
      ...mapState({
        token: state => state.token,
        application: state => state.application,

      }),

    },


    methods: {
      ...mapMutations({
        m_modal_login: 'm_modal_login',
        m_modal_reg: 'm_modal_reg',
      }),

      click_fastgen () {
        this.fastgen = true;
        this.vlngsa = false;
      },

      click_vlngsa () {
        this.fastgen = false;
        this.vlngsa = true;
      },

      go_back_welcome () {
        this.fastgen = false;
        this.vlngsa = false;
      },
    },


  }

</script>

<style scoped>
.dolu {
  position: relative;
  z-index: 1;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /* background-color: rgba(255, 255, 255, 0.7); */
  background-color: rgba(0, 0, 0, 0.8);
  /* background-color: rgba(0, 94, 114, 0.7); */


  z-index: -1;
}

.main-content {
  position: relative;
  z-index: 1; /* content is above overlay */
  padding: 20px; /* Adjust padding as needed */
}

.grid-content {
  position: relative;
  color: white; /* Text color */
  z-index: 2;
}

.text_a, .text_b {
  /* color: #000; */
  color: #white;
}



.text_a_bv {
  /* color: #000; */
  color: #1c3653 !important;
  font-size: 36px !important;
  font-weight: 900 !important;
}


.text_c_bv {
  /* color: #000; */
  color: #1c3653 !important;
  font-size: 24px !important;
  font-weight: 700 !important;
}


h2, h3, h4, p {
  margin: 11px 0;
}

a {
  color: #1c3653 !important; /* Bright blue */
  text-decoration: underline;
}

a.link_bioxsys {
  color: white !important; /* Bright blue */
  text-decoration: underline;
}

/* Change color when hovered */
a:hover {
  color: #0056b3; /* Darker blue */
}

</style>
