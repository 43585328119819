<template>
  <div class="card card-stats">
    <div class="card-body d-flex align-items-center">
      <div class="stats-icon text-center" :class="`text-${type}`">
        <i :class="icon"></i>
      </div>
      <div class="stats-content ms-3"> <!-- Added margin-left for spacing -->
        <p class="card-category">{{ smallTitle }}</p>
        <p class="card-title text-bold" :class="`text-${type}`">{{ formattedTitle }}</p>
      </div>
    </div>
    <div class="card-footer">
      <hr />
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'stats-card',
  props: {
    type: String, // primary, info, danger, warning, success
    title: [String, Number],
    smallTitle: String,
    icon: String
  },
  computed: {
    formattedTitle() {
      if (typeof this.title === "number") {
        return new Intl.NumberFormat('en-US').format(this.title);
      }
      return this.title;
    }
  }
}
</script>

<style scoped>
.card-stats {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s ease-in-out;
}

.card-stats:hover {
  transform: translateY(-4px);
}

/* Bigger icon with spacing */
.stats-icon {
  font-size: 50px;
  min-width: 60px;
}

/* Ensure some spacing between icon and text */
.stats-content {
  margin-left: 12px; /* Small spacing */
}

/* Bigger value */
.card-title {
  font-size: 28px;
  font-weight: bold;
  margin-top: 5px;
}

/* Category text */
.card-category {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
}

/* Bootstrap color classes */
.text-success {
  color: #28a745 !important;
}
.text-info {
  color: #17a2b8 !important;
}
.text-warning {
  color: #ffc107 !important;
}
.text-danger {
  color: #dc3545 !important;
}
.text-primary {
  color: #007bff !important;
}
</style>
