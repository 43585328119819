<template>
  <div class="row dolu" style="margin-top: 20px; padding: 0 20px;">
    <div class="col-md-12">
      <div class="card"
           style="border-radius: 12px; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); overflow: hidden; width: 100%;">

        <div class="card-header"
             style="background-color: white; color: #333; padding: 15px; font-size: 18px; font-weight: bold; border-bottom: 1px solid #ddd;">
          Choose Your Workspace
        </div>

        <div class="card-body" style="padding: 10px;">
          <el-table
            ref="roles"
            :data="roles"
            border
            highlight-current-row
            stripe
            style="width: 100%; font-size: 14px;"
            @current-change="handleCurrentChange"
          >
            <el-table-column min-width="50" prop="id_subject" label="Workspace ID" sortable align="left">
            </el-table-column>

            <el-table-column min-width="300" prop="subject" label="Workspace Name" sortable align="left">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Loading from 'src/components/Layout/LoadingMainPanel.vue'
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
  import axios from 'axios';

  export default {
    components: {

    },


    computed: {
      ...mapState({
        roles: state => state.roles,
        my_role: state => state.my_role,

      }),
    },


    data () {
      return {

      }
    },


    methods: {
      ...mapActions({
        a_my_role: 'a_my_role',

      }),


      handleLike (index, row) {
        alert(`Your want to like ${row.name}`)
      },


      handleEdit (index, row) {
        alert(`Your want to edit ${row.name}`)
      },


      handleDelete (index, row) {
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      },


      alertuj (props) {
        alert(props.row.id_role);
        console.log(props.row.id_role);
      },


      handleCurrentChange(val) {
        if (val && val.id_role > 0) {
          this.a_my_role(val.id_role);
        } else {
          this.a_my_role(0);
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .el-table .td-actions{
    button.btn {
      margin-right: 5px;
    }
  }



  .el-table td, .el-table th {
    padding: 10px 0 !important;
  }

  h4 {
    margin-top: 5px !important;
  }

  .dolu {
    padding-top: 80px !important;
  }
</style>
