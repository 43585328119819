<template>
  <div class="row dolu_sv">
    <div class="col-md-12">
      <glovars> </glovars>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import GloVars from 'src/components/Views/GloVars.vue'

  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import axios from 'axios';

  Vue.use(GloVars);

  export default {
    name: 'varexp',
    components: {
      [GloVars.name]: GloVars,

    },

    data () {
      return {

      }
    },

    computed: {
      ...mapState({
        user: state => state.user,
        vars_order: state => state.vars_order,
        er_switch: state => state.er_switch,

      }),

      ...mapGetters({

      }),

    },


    methods: {
      ...mapMutations({
        m_er_switch: 'm_er_switch',
        m_vars_order: 'm_vars_order',

      }),


      ...mapActions({
        a_glovars: 'a_glovars',

        }),

    },

    created () {
      this.a_glovars( { "order": this.vars_order, "er_switch": this.er_switch } );
    },

  }
</script>

<style scoped lang="scss">
.dolu_sv {
  padding-top: 100px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;

}


</style>
