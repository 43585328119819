<template>
  <div class="dolu">
    <!--Stats cards-->

    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-4">
        <stats-card :type="stat_global_projects.type"
                    :icon="stat_global_projects.icon"
                    :small-title="stat_global_projects.title"
                    :title="stat_global_projects.value">
          <div class="stats" slot="footer">
          </div>
        </stats-card>
      </div>

      <div class="col-lg-4 col-md-4 col-sm-4">
        <stats-card :type="stat_global_examinations.type"
                    :icon="stat_global_examinations.icon"
                    :small-title="stat_global_examinations.title"
                    :title="stat_global_examinations.value">
          <div class="stats" slot="footer">
          </div>
        </stats-card>
      </div>

      <div class="col-lg-4 col-md-4 col-sm-4">
        <stats-card :type="stat_global_workflows.type"
                    :icon="stat_global_workflows.icon"
                    :small-title="stat_global_workflows.title"
                    :title="stat_global_workflows.value">
          <div class="stats" slot="footer">
          </div>
        </stats-card>
      </div>


    </div>

<!--
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-2" v-for="stats in statsCards">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="stats.value">
          <div class="stats" slot="footer">
            <i :class="stats.footerIcon"></i>
            {{stats.footerText}}
          </div>
        </stats-card>
      </div>
    </div> -->




      <!-- <div class="row">
        <div class="col-md-12">
          <chart-card
            :chart-data="examinations_month.data"
            :chart-height="60"
            chart-id="activity-bar-chart"
            chart-type="Bar"
          >
            <template #header>
              <p class="card-category" style="font-size: 20px; font-weight: bold;">Overview of monthly examinations</p>
            </template>

            <template #footer>
              <hr>
              <div class="stats">
                <i class="fas fa-calendar-alt"></i> Updated today
              </div>
            </template>
          </chart-card>
        </div>
      </div> -->

      <div class="row">
        <div v-if="examinations_month.length !== 0" class="col-12 mr-auto ml-auto">
          <div class="card" style="max-width: 1700px; margin: auto; padding: 10px 20px;">
            <div class="col-md-12">
              <h4 class="stats-monthly-exmainations">Overview of Monthly Examinations</h4>
            </div>
            <div class="card-body" style="padding: 10px; display: flex; flex-direction: column; align-items: center;">
              <slot name="title-content">
                <div class="row" style="width: 100%;">
                  <div class="col-10">
                    <div class="numbers pull-left">
                      <slot name="title"></slot>
                    </div>
                  </div>
                  <div class="col-sm-5">
                    <div class="pull-right">
                      <slot name="title-label"></slot>
                    </div>
                  </div>
                </div>
              </slot>
              <slot name="subtitle"> Samples </slot>

              <!-- Graph container with full width -->
              <div style="width: 100%; max-width: 1500px;">
                <la-cartesian narrow :data="examinations_month" :width="1500">
                  <la-bar prop="value"></la-bar>
                  <la-x-axis :fontSize="13" prop="name"></la-x-axis>
                  <la-y-axis :fontSize="13" :nbTicks="4" gridline prop="XXXXXX" :format="v => parseInt(v)"></la-y-axis>
                  <la-tooltip></la-tooltip>
                </la-cartesian>
              </div>

              <div class="col-sm-12">
                <div class="pull-right">
                  <slot name="title-label"> Updated today </slot>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <slot name="footer">
                <hr>
                <div class="row">
                  <div class="col-sm-7">
                    <div class="footer-title">
                      <slot name="footer-title"></slot>
                    </div>
                  </div>
                  <div class="col-sm-5">
                    <div class="pull-right">
                      <slot name="footer-right"></slot>
                    </div>
                  </div>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>

<!--
      <div class="col-md-4">
        <chart-card :chart-data="activityChart.data"
                    :chart-height="200"
                    chart-id="activity-bar-chart"
                    chart-type="Bar">
          <template slot="header">
            <h4 class="card-title">2018 Sales</h4>
            <p class="card-category">All products including Taxes</p>
          </template>
          <template slot="footer">
            <div class="legend">
              <i class="fa fa-circle text-info"></i> Tesla Model S
              <i class="fa fa-circle text-danger"></i> BMW 5 Series
            </div>
            <hr>
            <div class="stats">
              <i class="fa fa-check"></i> Data information certified
            </div>
          </template>
        </chart-card>
      </div>

      <div class="col-md-4">
        <chart-card :chart-data="activityChart.data"
                    :chart-height="200"
                    chart-id="activity-bar-chart"
                    chart-type="Bar">
          <template slot="header">
            <h4 class="card-title">2018 Sales</h4>
            <p class="card-category">All products including Taxes</p>
          </template>
          <template slot="footer">
            <div class="legend">
              <i class="fa fa-circle text-info"></i> Tesla Model S
              <i class="fa fa-circle text-danger"></i> BMW 5 Series
            </div>
            <hr>
            <div class="stats">
              <i class="fa fa-check"></i> Data information certified
            </div>
          </template>
        </chart-card>
      </div> -->


    </div>


    </div>

  </div>
</template>
<script>
  import CircleChartCard from 'src/components/UIComponents/Cards/CircleChartCard.vue'
  import ChartCard from 'src/components/UIComponents/Cards/ChartCard';
  import StatsCard from 'src/components/UIComponents/Cards/StatsCard';
  import {Badge} from 'src/components/UIComponents'
  import TaskList from './Widgets/TaskList'

  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';


  export default {
    components: {
      StatsCard,
      ChartCard,
      CircleChartCard,
      Badge,
      TaskList
    },
    /**
     * Chart data used to render stats, charts. Should be replaced with server data
     */
    data () {
      return {
        statsCards_global: [
          {
            type: 'default',
            icon: 'nc-icon nc-globe',
            title: 'Total Analyzed Samples',
            value: '3',
            footerText: 'Updated now',
            footerIcon: 'nc-icon nc-refresh-69'
          },
          {
            type: 'warning',
            icon: 'nc-icon nc-globe',
            title: 'Total Analyzed Samples',
            value: '3',
            footerText: 'Updated now',
            footerIcon: 'nc-icon nc-refresh-69'
          },
          {
            type: 'warning',
            icon: 'nc-icon nc-globe',
            title: 'Total Analyzed Samples',
            value: '3',
            footerText: 'Updated now',
            footerIcon: 'nc-icon nc-refresh-69'
          },

        ],

        statsCards: [
          {
            type: 'warning',
            icon: 'nc-icon nc-globe',
            title: 'Total Analyzed Samples',
            value: '3',
            footerText: 'Updated now',
            footerIcon: 'nc-icon nc-refresh-69'
          },
          {
            type: 'danger',
            icon: 'nc-icon nc-vector',
            title: 'Total workflows',
            value: '3',
            footerText: 'pre-paid',
            footerIcon: 'nc-icon nc-bell-55'
          },
          {
            type: 'info',
            icon: 'nc-icon nc-favourite-28',
            title: 'Your friends',
            value: '5',
            footerText: 'Updated now',
            footerIcon: 'nc-icon nc-refresh-69'
          },
          {
            type: 'info',
            icon: 'nc-icon nc-bulb-63',
            title: 'New variant status',
            value: '3',
            footerText: 'Updated now',
            footerIcon: 'nc-icon nc-refresh-69'
          },

        ],


        activeUsersChart: {
          data: {
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"],
            series: [542, 480, 430, 550, 530, 453, 380, 434, 568, 610]
          }
        },
        emailsCampaignChart: {
          data: {
            labels: ["12pm", "3pm", "6pm", "9pm", "12am", "3am", "6am", "9am"],
            series: [40, 500, 650, 700, 1200, 1250, 1300, 1900]
          }
        },
        activeCountriesChart: {
          data: {
            labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October"],
            series: [80, 78, 86, 96, 83, 85, 76, 75, 88, 90]
          }
        },
        activityChart: {
          data: {
            labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, ],
            datasets: [
              {
                label: "Examinations",
                borderColor: '#fcc468',
                fill: true,
                backgroundColor: '#fcc468',
                hoverBorderColor: '#fcc468',
                borderWidth: 8,
                data: [100, 120, 80, 100, 90, 130, 110, 100, 80, 110, 130, 140, ],
              },

            ]
          }
        },




      }
    },

    computed: {
      ...mapState({
        stat_global_projects: state => state.stat_global_projects,
        stat_global_examinations: state => state.stat_global_examinations,
        stat_global_workflows: state => state.stat_global_workflows,
        examinations_month: state => state.examinations_month,

      }),

      ...mapGetters({

      }),

    },


    methods: {
      ...mapMutations({

      }),


      ...mapActions({
        a_dashboard: 'a_dashboard',

        }),



    },

    beforeCreate () {
      // this.a_dashboard();
    },


    beforeMount () {
      this.a_dashboard();
    },


    mounted () {
      // this.a_dashboard();

    },


    beforeUpdate () {
      // this.a_dashboard();

    },


  }

</script>
<style scoped>
.dolu {
  padding-top: 100px !important;
}

.card-category {
  font-size: 20px;
  font-weight: bold;
}

.card {
  border-radius: 10px !important;
}

.stats-monthly-exmainations {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #9a9a9a; /* Adjust if needed */
}

</style>
